import React from "react";
import { Spin } from "antd";

import "./ChartWidget.css";

import CustomList from "../CustomList";

const TableWidget = ({
  label,
  number,
  percentage,
  stat,
  split,
  size,
  format = null,
  loading = false,
}) => {
  const updatedNumber = format === "currency" ? `$${number}` : number;
  return (
    <div className="widget flex flex-col">
      <p className="label">{label}</p>
      {loading ? (
        <div className="grow flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <p>
            <span className="number mr-2">{updatedNumber}</span>
            <span className="percentage">{percentage}</span>
          </p>
          <div className="stat">
            <CustomList list={stat} split={split} size={size} />
          </div>
        </>
      )}
    </div>
  );
};

export default TableWidget;
