import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import React from "react";

import "./App.css";

import MainLayout from "./components/MainLayout";
import Reports from "./features/Reports";
import Purchases from "./features/Purchases";
import Login from "./features/Login";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("auth_token")?.length === 16;
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

const LoginRoute = () => {
  const isAuthenticated = localStorage.getItem("auth_token")?.length === 16;

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <Login />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login/*"
          element={
            <Routes>
              <Route path="/" element={<LoginRoute />} />
            </Routes>
          }
        />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/reports" replace />}
                  />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/purchases" element={<Purchases />} />
                  {/* Add more routes for the MainLayout here */}
                </Routes>
              </MainLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
