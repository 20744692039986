import dayjs from "dayjs";
import { message } from "antd";
import { useEffect, useState } from "react";
import { Form, Input, DatePicker, Button, Space } from "antd";

import axios from "../axiosConfig";

const AddEditAppLink = ({ defaultValue, onUpdate }) => {
  // const [isVisibleDateField, setIsVisibleDateField] = useState(true);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (defaultValue) {
      const uploadDate = dayjs(defaultValue.Creation_Date);
      form.setFieldsValue({
        AppLink: defaultValue.App_link,
        UploadDate: uploadDate,
      });
      setDate(uploadDate);
      // if (defaultValue._id) {
      //   //hide date field in case of edit, as created date can not be updated
      //   setIsVisibleDateField(false);
      // }
    }
  }, [defaultValue]);

  const onFinish = async (values) => {
    // Handle form submission
    try {
      setIsLoading(true);
      const isEditMode = typeof defaultValue?._id === "string";

      const apiURL = isEditMode
        ? `updatelink/${defaultValue._id}`
        : `applinkcreate`;
      const request = isEditMode ? axios.patch : axios.post;

      const response = await request(apiURL, values);
      const successMessage = response.data.message;
      onUpdate();
      //Display success message
      message.success(successMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // Handle form submission failure
    console.log(errorInfo);
  };

  const onReset = () => {
    form.resetFields();
    setDate(null);
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };

  const handleDateChange = (date) => {
    form.setFieldsValue({
      UploadDate: date,
    });
    setDate(date);
  };

  return (
    <Form
      name="applink details"
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="App Link"
        name="AppLink"
        rules={[
          {
            required: true,
            message: "Please input your App Link",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Date"
        name="UploadDate"
        rules={[
          {
            type: "object",
            required: true,
            message: "Please input your Date",
          },
        ]}
      >
        <Space direction="vertical">
          <DatePicker
            className="w-full"
            disabledDate={disabledDate}
            value={date}
            onChange={handleDateChange}
          />
          {/* {isVisibleDateField ? (
            <DatePicker value={dayjs(defaultValue.Creation_Date)} onChange={handleDateChange} />
          ) : (
            dayjs(defaultValue.Creation_Date).format("DD-MM-YYYY")
          )} */}
        </Space>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
        <Button
          htmlType="button"
          className="ml-3"
          loading={isLoading}
          onClick={onReset}
        >
          Reset
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddEditAppLink;
