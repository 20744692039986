import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "antd";

import axios from "../axiosConfig";
import { UserContext } from "../context/UserContext";

import CustomDataTable from "../components/table/CustomDataTable";
import UpdateScanCount from "../components/UpdateScanCount";

function Purchases() {
  const { setSelectedUsers, toggleCleared } = useContext(UserContext);

  const [allUsers, setAllUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [visible, setVisible] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const userColumns = [
    {
      name: "Customer Id",
      key: "CustomerId",
    },
    {
      name: "Email ID",
      key: "email",
    },
    {
      name: "Last Login",
      key: "last_login",
    },
    {
      name: "Scans Completed",
      key: "Total_Scans_Till_Now",
    },
    {
      name: "Remaining Scans",
      key: "Scan_Count_limit_forthis_Month",
      isCustomCell: true,
      cell: (row) => (
        <Button
          type="link"
          onClick={() => {
            setUserDetails({ ...row });
            toggleModal(true);
          }}
        >
          {row.Scan_Count_limit_forthis_Month}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    // Fetch user list
    const getAllUsers = async () => {
      try {
        const response = await axios.get("totalUsers");
        const { data } = response;
        if (data.status) {
          setAllUsers(data.data);
          setUserCount(data.usercount);
        } else {
          console.log("Error fetching user list:", data.message);
        }
      } catch (error) {
        console.log("Error fetching user list:", error);
      } finally {
        setFetching(false);
      }
    };

    getAllUsers();
  }, []);

  const toggleModal = (isVisible) => {
    setVisible(isVisible);
  };

  const onUpdate = (updatedUserData) => {
    const { Email, Scn_Count_Remaining } = updatedUserData;

    const updatedAllUsers = allUsers.map((user) => {
      if (user.email === Email) {
        return { ...user, Scan_Count_limit_forthis_Month: Scn_Count_Remaining };
      }
      return user;
    });

    setAllUsers(updatedAllUsers);

    toggleModal(false);
  };

  const onSelectUser = ({ selectedRows }) => {
    setSelectedUsers(selectedRows);
  };

  return (
    <>
      <Modal
        title="Update Scan Count"
        open={visible}
        onCancel={() => {
          toggleModal(false);
          setUserDetails(null);
        }}
        footer={null}
      >
        {userDetails ? (
          <UpdateScanCount details={userDetails} onUpdate={onUpdate} />
        ) : null}
      </Modal>
      <CustomDataTable
        columns={userColumns}
        data={allUsers}
        total={userCount}
        pending={fetching}
        selectable={true}
        clearSelection={toggleCleared}
        onSelect={onSelectUser}
      />
    </>
  );
}

export default Purchases;
