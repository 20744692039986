import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "../axiosConfig";
import { Button, Form, Input, Alert } from "antd";
import { generateUUID } from "../utils/functions";

export default function Login() {
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post("dasboardlogin", {
        username: values.username,
        password: values.password,
      });
      const data = response.data;
      if (data.status) {
        console.log("Success:", data.message);
        // Handle successful login here
        const Id = generateUUID();
        storeAuthToken(Id);
        navigate("/reports"); // Navigate to /reports
      }
    } catch (error) {
      console.log("Error:", error);
      // Handle network or other errors here
      const data = error.response.data;
      setLoginError(data.error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const storeAuthToken = (authToken) => {
    localStorage.setItem("auth_token", authToken);
  };

  const onFinish = (values) => {
    handleLogin(values);
  };

  return (
    <section className="h-screen">
      <div className="h-full">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
            <img
              src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="w-full"
              alt="Sample image"
            />
          </div>

          <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
            <p className="mb-5 text-center font-bold text-4xl">
              Purvi Dash Board
            </p>
            <Form
              name="basic"
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              size="large"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password autoComplete="off" />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>

              {/* <div className="text-center lg:text-left">
                <p className="mb-0 mt-2 pt-1 text-sm font-semibold">
                  Don't have an account?
                  <a
                    href="#!"
                    className="text-danger transition duration-150 ease-in-out hover:text-danger-600 focus:text-danger-600 active:text-danger-700"
                  >
                    Sign up
                  </a>
                </p>
              </div> */}
            </Form>
            {loginError && (
              <div className="md:w-10/12 mt-5">
                <Alert description={loginError} type="error" showIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
