import React from "react";
import ChartWidget from "../components/Widgets/ChartWidget";
import ListWidget from "../components/Widgets/ListWidget";
import "./DashBoard.css";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import axios from "../axiosConfig.js";
import DataWidget from "../components/DataWidget";

function DashBoard() {
  // const users = [
  //   { label: "New", value: 62 },
  //   { label: "Returning", value: 13 },
  //   { label: "Inctive", value: 23 },
  // ];

  // const subscriptions = [
  //   { label: "Paid", value: 40 },
  //   { label: "Trial", value: 60 },
  // ];

  // const scans = [
  //   {
  //     label: "May",
  //     value: "$283",
  //     icon: faCaretUp,
  //     iconClass: "text-blue-600",
  //   },
  //   {
  //     label: "April",
  //     value: "$283",
  //     icon: faCaretUp,
  //     iconClass: "text-blue-600",
  //   },
  //   {
  //     label: "Feb",
  //     value: "$283",
  //     icon: faCaretUp,
  //     iconClass: "text-blue-600",
  //   },
  //   {
  //     label: "Earlier",
  //     value: "$283",
  //     icon: faCaretDown,
  //     iconClass: "text-orange-500",
  //   },
  // ];

  const updateRevenueData = function (revenueData) {
    return [
      {
        label: "Current",
        value: `$${revenueData}`,
        icon: faCaretUp,
        iconClass: "text-blue-600",
      },
    ];
  };

  const updateScansData = function (scansData) {
    return [
      {
        label: "All",
        value: scansData,
        icon: faCaretUp,
        iconClass: "text-blue-600",
      },
    ];
  };

  const updateData = function (data) {
    return [
      {
        label: "All",
        value: data,
      },
    ];
  };

  return (
    <div className="DashBoard">
      {/* Render your components using the updated 'revenues' variable */}
      <div className="dashboard__widget--small">
        <DataWidget
          url="totalUsers"
          dataModifier={updateData}
          widgetType="chart"
          label="Users"
          percentage="+40%"
          type="doughnut"
        />
      </div>

      <div className="dashboard__widget--small">
        <DataWidget
          url="totalsubscribers"
          dataModifier={updateData}
          widgetType="chart"
          label="Subscriptions"
          percentage="+10%"
          type="doughnut"
        />
      </div>

      <div className="dashboard__widget--small">
        <DataWidget
          url="totalrevenue"
          dataModifier={updateRevenueData}
          widgetType="list"
          label="Revenue"
          percentage="+40%"
          format="currency"
        />
      </div>

      <div className="dashboard__widget--small">
        <DataWidget
          url="totalscans"
          dataModifier={updateScansData}
          widgetType="list"
          label="Total successful scans"
          percentage="+40%"
        />
      </div>
    </div>
  );
}

export default DashBoard;
