import React, { useEffect, useState } from "react";
import axios from "../axiosConfig";

import ChartWidget from "./Widgets/ChartWidget";
import ListWidget from "./Widgets/ListWidget";

export default function DataWidget({ url, dataModifier, widgetType, ...rest }) {
  const [data, setData] = useState(null);
  const [updatedData, setUpdatedData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        if (response.data.status) {
          const { usercount = null, data: responseData } = response.data;
          setData(usercount || responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching revenue:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (data !== null) {
      let updatedData = data;
      if (typeof dataModifier === "function") {
        updatedData = dataModifier(updatedData);
      }
      setUpdatedData(updatedData);
    }
  }, [data]);

  const Widget = widgetType === "list" ? ListWidget : ChartWidget;

  return (
    <Widget stat={updatedData} number={data} loading={loading} {...rest} />
  );
}
