import React, { useState, useEffect } from "react";
import { Button, Modal, Alert, message } from "antd";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import ButtonWithPrompt from "./ButtonWithPrompt";
import AddEditAppLink from "./AddEditAppLink";

import axios from "../axiosConfig";
import dayjs from "dayjs";
import { UserContext } from "../context/UserContext";

export default function MainLayout({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appDetails, setAppDetails] = useState({
    App_link: "",
    Creation_Date: new Date(),
  });
  const [loading, setLoading] = useState(true);
  const [daysRemainingUntilExpiry, setDaysRemaining] = useState(0);
  const [isVisibleLicenseBanner, toggleLicenseBanner] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAppList();
  }, []);

  useEffect(() => {
    const { ExpirationDate } = appDetails;
    const daysRemaining = dayjs(ExpirationDate).diff(dayjs(), "day");
    setDaysRemaining(daysRemaining);
    toggleLicenseBanner(true);
  }, [appDetails]);

  const fetchAppList = async () => {
    try {
      const response = await axios.get("applist");
      if (response.data.status && response.data.data.length) {
        setAppDetails(response.data.data[0]);
      }
    } catch (error) {
      console.log("Error fetching app list:", error);
    } finally {
      setLoading(false);
    }
  };

  const sendEmailToUsers = async () => {
    try {
      setSendingEmail(true);

      const userEmailList = selectedUsers.map((user) => user.email).join(",");
      // const userEmailList = "smritikanna@determinantstudios.com";
      const form = new FormData();
      form.append("sent_email_recipents", userEmailList);

      const response = await axios.post("sentemailmanually", form);
      const successMessage = response.data.message;
      message.success(successMessage);

      clearSelection();
    } catch (error) {
      console.error("Error", error);
    } finally {
      setSendingEmail(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpdateLink = () => {
    setIsModalOpen(false);
    fetchAppList();
  };

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    navigate("/login");
  };

  const clearSelection = () => {
    setToggleCleared(!toggleCleared);
    setSelectedUsers([]);
  };

  let appLinkButtonText = "Create App Link";
  if (appDetails._id) {
    appLinkButtonText = "Update App Link";
  }

  const licenseExpiryText = `Your license will expire in: ${daysRemainingUntilExpiry} days`;
  const isLessThanTenDaysUntilExpiry = true;
  //daysRemainingUntilExpiry <= 10;

  const promptTitle = "Send Email";
  const promptDescription = `Are you sure, you want to send email to ${selectedUsers.length} recipients?`;

  return (
    <UserContext.Provider
      value={{
        selectedUsers,
        setSelectedUsers,
        toggleCleared,
        setToggleCleared,
      }}
    >
      <div className="bg-gray-100 flex flex-row">
        <Sidebar />
        <main className="flex-1 h-screen p-4 overflow-y-auto ml-sidebar relative">
          {isVisibleLicenseBanner ? (
            <div className="absolute top-0 left-0 w-full">
              <Alert
                message={licenseExpiryText}
                type="warning"
                closable
                onClose={() => toggleLicenseBanner(false)}
              />
            </div>
          ) : null}
          <div
            className={[
              "flex",
              "justify-between",
              isVisibleLicenseBanner ? "mt-10" : null,
            ].join(" ")}
          >
            <h1 className="text-xl font-bold">Pūrscan Dashboard</h1>
            <div>
              <ButtonWithPrompt
                promptTitle={promptTitle}
                promptDescription={promptDescription}
                disabledButton={selectedUsers.length === 0}
                confirmLoading={sendingEmail}
                onClickOk={sendEmailToUsers}
              >
                Send Email ({selectedUsers.length})
              </ButtonWithPrompt>
              {isLessThanTenDaysUntilExpiry && (
                <Button type="primary" loading={loading} onClick={showModal}>
                  {appLinkButtonText}
                </Button>
              )}
              <Button
                className="ant-btn-secondary"
                onClick={handleLogout}
                style={{ marginLeft: "8px" }}
              >
                Logout
              </Button>
            </div>
            <Modal
              title={appLinkButtonText}
              open={isModalOpen}
              footer={null}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              {isLessThanTenDaysUntilExpiry && (
                <AddEditAppLink
                  defaultValue={appDetails}
                  onUpdate={handleUpdateLink}
                />
              )}
            </Modal>
          </div>
          <hr className="border-t-1 my-5 border-gray-300" />
          {children}
        </main>
      </div>
    </UserContext.Provider>
  );
}
