import React, { useState } from "react";
import { Button, Popconfirm } from "antd";

const ButtonWithPrompt = ({
  children,
  promptTitle = "Title",
  promptDescription = "Description",
  confirmLoading = false,
  disabledButton = false,
  onClickOk = () => {},
}) => {
  const [open, setOpen] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    try {
      await onClickOk();
    } finally {
      setOpen(false);
    }
  };

  return (
    <Popconfirm
      title={promptTitle}
      description={promptDescription}
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button
        type="primary"
        className="mx-3"
        disabled={disabledButton}
        onClick={showPopconfirm}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

export default ButtonWithPrompt;
