import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "tailwindcss/tailwind.css";
import CustomDataTableHeader from "./CustomDataTableHeader";

function CustomDataTable({
  columns,
  data,
  total = 0,
  pending = false,
  selectable = false,
  clearSelection = false,
  onSelect = () => {},
}) {
  const getColumnJSX = (name) => (
    <span className="bg-gray-100 text-gray-600 px-2 py-1 rounded-md">
      {name}
    </span>
  );

  const [searchTerm, setSearchTerm] = useState("");

  const modifiedColumns = columns.map((column) => {
    const nameJSX = getColumnJSX(column.name);

    if (column.isCustomCell) {
      return {
        name: nameJSX,
        cell: column.cell,
      };
    } else {
      return {
        name: nameJSX,
        cell: (row) => {
          const cellValue = row[column.key];
          if (cellValue === "") return "-";
          if (typeof cellValue === "string" && column.type === "date") {
            const formattedDate = formatDate(cellValue);
            return (
              <span className="text-gray-500 text-sm">{formattedDate}</span>
            );
          } else {
            return <span className="text-gray-500 text-sm">{cellValue}</span>;
          }
        },
      };
    }
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[monthIndex];

    return `${day}-${month}-${year}`;
  };

  const updatedData = data.filter((item) => {
    if (searchTerm === "") {
      return item;
    } else if (item.email.toLowerCase().includes(searchTerm.toLowerCase())) {
      return item;
    }
  });

  const tableHeader = (
    <CustomDataTableHeader total={total} search={setSearchTerm} />
  );
  return (
    <DataTable
      title={tableHeader}
      columns={modifiedColumns}
      data={updatedData}
      progressPending={pending}
      pagination
      selectableRows={selectable}
      onSelectedRowsChange={onSelect}
      clearSelectedRows={clearSelection}
    />
  );
}

export default CustomDataTable;
