import { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";

import axios from "../axiosConfig";

const UpdateScanCount = ({ details, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [highlightRow, setHighlightRow] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      Scn_Count: details.Scan_Count_limit_forthis_Month,
    });
  }, [details]);

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      // Make the API call to update the scan count
      const response = await axios.patch(
        `updatescans/${details.CustomerId}`,
        values
      );

      // Check if the API call was successful
      if (response.status === 200) {
        const successMessage = response.data.message;
        //Display success message
        message.success(successMessage);

        const updatedData = response.data.data[0];
        onUpdate(updatedData);
        // Highlight the table row
        setHighlightRow(true);
        setTimeout(() => {
          setHighlightRow(false);
        }, 3000); // Remove the highlight after 3 seconds
      }
    } catch (error) {
      console.log("Error updating scan count:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Remaining Scans"
        name="Scn_Count"
        rules={[
          {
            required: true,
            message: "Scan count can not be empty",
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdateScanCount;
